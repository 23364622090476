.seeker
  width 100%
  height 24px
  position relative

  &__track
    width 100%
    height 100%
    border-radius 3px
    background rgba(0, 0, 0, 0.05)

  &__indicator
    top 0
    left 0
    z-index 1
    width 100px
    height 24px
    max-width 100%
    position absolute
    cursor col-resize
    box-sizing border-box

    &::before
      top 1px
      bottom 1px
      left 1px
      right 1px
      content ''
      display block
      position absolute
      border-radius 3px
      background #fff
      box-shadow 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(0, 0, 0, 0.2)

  &__position
    top 0
    left 0
    width 1px
    z-index 2
    cursor grab
    height 100%
    position absolute
    background-color #000

    &::before
      top 0
      bottom 0
      left -5px
      right -5px
      content ''
      display block
      position absolute

    &:hover
      border-radius 30px
      box-shadow 0 0 0 3px rgba(#000, 0.2)

  &__minimap
    top 0
    left 0
    z-index 3
    width 100%
    height 100%
    position absolute
    pointer-events none
