.node
  flex-basis 100%
  color var(--labelColor, rgba(0, 0, 0, 0.6))

  overflow hidden
  white-space nowrap
  text-overflow ellipsis
  display flex

  &__incomplete
    display inline-flex
    margin-left 5px
    align-items center
    svg
      width 20px
      height 17px
      fill var(--incomplete-warning-color, #FA8C16)

.node-minimal
  display inline-flex
  align-items center

  &__counter
    flex none
    padding-right 7px
    display flex
    color #4F00FF
    align-items center
    justify-content center

  &__icon
    width 24px
    height 24px
    margin-right 5px

    svg
      width 24px
      height 24px

