.bottombar
  .controls
    flex 1
    height 100%
    display grid
    grid-auto-columns 1fr
    column-gap 8px
    grid-auto-flow column
    justify-content flex-end
    padding-right 8px
    align-items center

    .button
      width 160px
      height 40px
      font-size 16px
      line-height 24px
      &_look_danger
        border-color #e1e0e2

    .submit
      border-radius 4px
      background #09F
      box-shadow 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
      display flex
      width 160px
      height 40px
      justify-content center
      align-items center
      color #FFF
      text-align center
      font-size 16px
      font-style normal
      font-weight 500
      line-height 24px
      letter-spacing 0.15px
      flex-direction row-reverse
      padding 0

      &_disabled 
        color: rgba(255,255,255,0.8);
        background-color: #bbb;

      &__has_icon
        justify-content space-between

      span  
        width 100%

      &__icon
        display flex
        max-width 40px 
        height 100%
        justify-content center
        align-items center
        align-self stretch
        border-left 1px solid rgba(255, 255, 255, 0.16)

        div
          width 100%
          height 100%
          display flex
          align-items center
          justify-content center

        svg 
          pointer-events none
          transform: rotate(180deg) scale(1.1)

    &__skipped-info
      display flex
      // background-color rgba(221, 0, 0, 0.18)
      align-items center
      justify-content center
      font-weight 500
      font-size 16px

      svg
        margin 0 8px 0 4px

  .action-dialog
    height 278px
    width 319px // 320 sidebar width - 1ps border
    padding 12px 16px
    display flex
    flex-direction column

    &__input-title
      margin-bottom 4px

    &__input
      resize none
      flex 1 1 auto
      margin-bottom 16px
      background-color #FAFAFA
      font-size 16px

    &__footer
      display flex
      flex-direction row
      justify-content end

.list-button
  width 169px
  position absolute
  right -40px
  bottom 40px
  background #FAFAFA
  box-shadow: 0px 1px 3px 1px rgba(38, 38, 38, 0.15), 0px 1px 2px 0px rgba(38, 38, 38, 0.30);
  font-size 16px
  font-style normal
  font-weight 400
  line-height 24px
  letter-spacing 0.5px

  &:hover:before 
    content ''
    z-index auto
    background #00f
    width calc(100% - 16px)
    height calc(100% - 16px)
    position absolute
    left 0
    top 0
    margin 8px
    border-radius 4px
    opacity 0.08
    background rgb(9 109 217)
    pointer-events none
