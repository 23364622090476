.taxonomy
  margin-top 1em
  margin-bottom 1em

  &__loading
    border 1px solid #d9d9d9
    border-radius 6px
    height 38px
    display flex
    align-items center
    justify-content center
    margin-top 42px
    margin-bottom 14px
    width 90px
    position relative

    & > div
      height 14px

    & > div > span
      display block
