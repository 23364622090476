.region-item
  width: 100%

  &__header
    height 32px
    display flex
    align-items center
    font-size 14px
    line-height 22px
    width 100%
    padding 0 8px

  &__counter
    flex none
    width 24px
    display flex
    color #999
    align-items center
    justify-content center

    ~/_hidden &
      opacity: 0.4;

  &__title
    ~/_hidden &
      opacity: 0.4;

  &__id
    display flex
    color: var(--labelColor);

    ~/_hidden &
      opacity: 0.4;

    svg
      width 24px
      height 24px

  &__score
    ~/_hidden &
      opacity: 0.4;

  &__toggle
    width: 24px
    flex: 0 0 24px;
    padding: 0 !important
    text-align: center;
    padding 0

  &__desc
    position relative
    background #f7f7f7
    padding 7px 32px 7px 12px
    border-top: 1px solid #e5e5e5;
    border-bottom-left-radius 5px
    border-bottom-right-radius 5px
    min-height 36px

    ~/_hidden &
      background: #fff;;

    &_empty
      display none

    &_collapsed
      height: 36px
      overflow hidden

  &__collapse
    position absolute
    width: 24px
    height: 24px
    top: 6px
    right: 8px
    opacity 0.25
    padding: 0

  &__controls:empty + &__collapse
    display none

  &__id
    width 16px
    height 16px

    svg
      width 100%
      height 100%
      color rgba(#000, 0.5)

  &__prediction
    width 24px
    height 24px
    display flex
    align-items center
    justify-content center

    svg
      width 100%
      height 100%
      color #7F64FF
