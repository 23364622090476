.timeline-controls
  background #fff

  &__counter
    height 36px
    min-width 115px
    display flex
    align-items center
    justify-content center
    background rgba(#000, 0.05)
    border-radius 4px

    font-weight 500
    font-size 16px
    line-height 19px
    color #000
    padding 0 8px

    span
      opacity 0.4
      padding-left 5px
      font-weight 400

    input
      width 100%
      height 100%
      border none
      background none
      padding 0
      margin 0
      font-size 16px
      line-height 19px
      text-align center

  &__main-controls
    display flex
    max-width 310px
    justify-content space-between

  .button
    background none
    width 36px
    height 36px

    &:hover
      box-shadow inset 0px -1px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15)

    &:disabled
      opacity 0.3

  &__time
    height 36px
    display flex
    font-size 16px
    border-radius 4px
    color rgba(#000, 0.6)
    box-shadow inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)

    &-section
      display flex
      height 100%
      padding 0 8px
      align-items center

      span
        opacity 0.48

        &::before
          content ":"

      & + &
        border-left 1px solid rgba(#000, 0.1)

.timeline-time
  padding 0 5px
  font-size 16px
  display inline-flex
  align-items center
  color rgba(#000, 0.4)

  span
    font-size 11px
    min-width 22px
    border-radius 2px
    text-align center
    margin-left 5px
    display inline-block
    color rgba(#000, 0.4)
    background rgba(0, 0, 0, 0.05)
