.toggle
  width 42px
  height 24px
  display block
  min-width 42px
  max-height 24px
  border-radius 12px
  position relative
  cursor pointer
  color var(--toggle-color, $accent_color)
  transition box-shadow 80ms ease
  background linear-gradient(0deg, rgba(#000, 0.05), rgba(#000, 0.05)), #FFFFFF
  box-shadow inset 0px 1px 0px rgba(#000, 0.05), inset 0px 0px 0px 1px rgba(#000, 0.05)

  &__input
    top 0
    left 0
    margin 0
    opacity 0
    padding 0
    width 100%
    height 100%
    position absolute

  &__indicator
    height 24px
    width 24px
    display flex
    align-items center
    justify-content center
    transition all 120ms ease

    &::before
      content ""
      width 10px
      height 10px
      border-radius 100%
      transition all 120ms ease
      background rgba(#000, 0.12)
      box-shadow 0px 5px 10px rgba(#000, 0.15), inset 0px -1px 0px rgba(#000, 0.1), inset 0px 0px 0px 1px rgba(#000, 0.05)

  &_checked &__indicator
    margin-left 18px

    &::before
      width 16px
      height 16px
      background currentColor
      box-shadow 0px 5px 10px rgba($accent_color, 0.3), inset 0px -1px 0px rgba(0, 0, 0, 0.1)

  &_disabled
    box-shadow none
    pointer-events none
    background rgba(0, 0, 0, 0.08)

  &_disabled &__indicator
    transition none

  &_disabled &__indicator::before,
  &_disabled&_checked &__indicator::before
    box-shadow none
    transition none
    background-color rgba(0, 0, 0, 0.12)
