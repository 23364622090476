.details 
.history 
.relations 
.info
.comments-panel
  --details-section-border-color #E1E0E0
  width: 100%

  &__section-tab
    width: 100%

    &-head
      font-family "Roboto", Arial, sans-serif;
      font-weight 500;
      font-size 15px

  &__section
    border-top 1px solid var(--details-section-border-color)
    width: 100%

    &:last-child
      border-bottom 1px solid var(--details-section-border-color)

    &-head
      display flex
      height 48px
      padding 0 16px
      font-size 15px
      font-weight 500
      align-items center
      justify-content space-between

      span
        opacity 0.6
        font-weight normal

.region-actions
  padding 8px
  height 52px
  display flex
  align-items stretch
  justify-content space-between
  position sticky
  bottom 0
  background white

  &__group
    display grid
    grid-column-gap 8px
    grid-auto-flow column
    grid-auto-columns 36px
    align-items center

    &_align
      &_left
        justify-content flex-start

      &_right
        justify-content flex-end

.comments-panel
  &__section-tab
    padding-top 16px

.history 
  &__section-tab
    padding 8px