.annotation-tabs
  height 44px
  width 100%
  display flex
  display grid
  z-index 100
  position sticky
  grid-template-columns 1fr
  top var(--sticky-items-offset)
  background-color var(--main-bg-color)
  border-bottom 1px solid rgba(0, 0, 0, 0.1)

  &_viewAll
    grid-template-columns 1fr 44px

  &_addNew
    grid-template-columns 44px 1fr

  &_viewAll&_addNew
    grid-template-columns 44px 1fr 44px

  &__add,
  &__all
    border none
    width 44px
    height 44px
    padding 0
    margin 0
    cursor pointer
    font-size 16px
    background none
    display flex
    align-items center
    justify-content center

  &__add
    border-right 1px solid rgba(#000, 0.1)

  &__all
    outline none
    color rgba(#000, 0.6)

    &_active
      color $accent_color

  &__list
    flex 1
    display flex
    overflow auto
    position relative
    -ms-overflow-style none
    scrollbar-width none

    &::-webkit-scrollbar
      display none

.entity-tab
  height 100%
  min-width 170px
  display flex
  cursor pointer
  align-items center
  padding 10px 12px
  box-sizing border-box

  &_bordered
    border-right 1px solid rgba(#000, 0.1)

  &_selected
    opacity 1
    cursor default
    background-color var(--main-bg-color)
    box-shadow inset 0 2px 0 $accent_color

  &__userpic
    width 24px
    height 24px

    &_prediction
      color #944BFF
      background-color #E9DDF8
      box-shadow inset 0px 0px 0px 1px rgba(148, 75, 255, 0.15)

  &__ground-truth
    color #ffbb1a
    margin-top -2px

    path
      fill-opacity 1
      stroke-opacity 1

  &__skipped
    color #d00
