.history-buttons
  display flex
  gap 8px
  margin-left: 8px

  &__action
    width 36px
    height 36px
    border none
    padding 0 !important
    background none !important

    &:disabled
      opacity 0.6

    svg
      display block

    &_delete
      color #DD0000
