.audio-slider
  --background-active-color #BAE7FF
  --button-color #0099FF

  position:relative

  &__range
    margin 0 20px
    width calc(100% - 40px)
    padding 0
    -webkit-appearance: none
    height: 4px
    background: #413C4A14
    border-radius: 8px
    background-image: linear-gradient(var(--background-active-color), var(--background-active-color))
    background-size: 70% 100%
    background-repeat: no-repeat
    border none

  &__control
    padding 0 16px;
    margin-top 10px
    display flex
    flex-wrap wrap
    justify-content space-between
    align-items center
    font-size 11px

  &__info
    display flex

  &__input
    width 40px
    height 24px
    border-radius  2px
    background  #F5F4F6
    border  solid 1px #89809829
    line-height  24px
    padding  0
    text-align center
    outline none;

    &_error_control
      color #CF1322
      border solid 1px #CF132229

  input[type="range"]::-webkit-slider-thumb
    -webkit-appearance: none
    height: 12px
    width: 12px
    border-radius: 50%
    background: var(--button-color)
    cursor: ew-resize
    transition: background .3s ease-in-out
    z-index 2
  
  input[type="range"]::-moz-range-thumb
    -webkit-appearance: none
    height: 12px
    width: 12px
    border-radius: 50%
    background: var(--button-color)
    cursor: ew-resize
    box-shadow: 0 0 2px 0 #555
    transition: background .3s ease-in-out
  
  input[type="range"]::-ms-thumb
    -webkit-appearance: none
    height: 12px
    width: 12px
    border-radius: 50%
    background: var(--button-color)
    cursor: ew-resize
    box-shadow: 0 0 2px 0 #555
    transition: background .3s ease-in-out
  
  input[type="range"]::-webkit-slider-thumb:hover
    background: var(--button-color)
  

  input[type="range"]::-moz-range-thumb:hover 
    background: var(--button-color)
  
  input[type="range"]::-ms-thumb:hover
    background: var(--button-color)
  
  input[type=range]::-webkit-slider-runnable-track
    -webkit-appearance: none
    box-shadow: none
    border: none
    background: transparent
  
  input[type=range]::-moz-range-track
    -webkit-appearance: none
    box-shadow: none
    border: none
    background: transparent
  

  input[type="range"]::-ms-track 
    -webkit-appearance: none
    box-shadow: none
    border: none
    background: transparent
  
