.draft-panel
  margin 8px 0
  &__toggle
    padding 0
    border 0
    vertical-align -0.5px
    height auto
    color $accent_color
    background none
    padding 0
    cursor pointer
    &:hover
      opacity 0.8
