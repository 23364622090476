.filter-row
  display flex
  flex-direction row
  align-items center
  margin-bottom 8px

  &__title-row
    width 60px
    text-align right

  &__delete
    cursor pointer
    height 16px

  &__column
    display flex
    margin-right 8px

    input
      height 24px !important