.entities
  margin-top 12px
  padding-top 12px
  border-top 1px solid rgba(#000, 0.1)
  border-bottom 1px solid rgba(#000, 0.1)

  &__header
    font-size 16px
    line-height 22px
    color rgba(0, 0, 0, 0.6)
    height 46px
    display flex
    align-items center
    padding 12px 15px 12px 24px
    border-bottom 1px solid rgba(#000, 0.1)

  &__sort
    display flex
    align-items center

  &__sort-icon
    display flex
    align-items center
    justify-content: center;
    width: 24px
    height: 24px
    margin-right: 4px

  &__source
    padding 0 15px

  &__counter
    color: rgba(0, 0, 0, 0.4)

  &__empty
    height 32px
    display flex
    padding 0 15px
    align-items center

  &__visibility
    padding: 0 !important
    width: 24px

    &_hidden
      opacity 1

  &__regions,
  &__labels
    padding 0

:global(.ant-tree .ant-tree-node-content-wrapper)
  min-width 0

:global(.ant-tree-draggable-icon)
  flex 14px 0 0
