.annotation
  padding 16px 16px 4px

  &__title
    padding 0 0 8px
    font-weight 500

  &__id
    font-weight 400
    opacity 0.6

  &__id
    display flex
    align-items center

  &__type
    width 14px
    height 14px
    margin-right 5px

    svg
      width 100%
      height 100%
      display block

  &__text_id
    position: relative
    top: -3px

  &__info
    height 32px
    display flex
    align-items center
    justify-content space-between
    margin-left 9px

  &__parent
    display flex
    align-items: center

  &__parent_info
    position relative
    height 20px
    display flex
    align-items center
    justify-content space-between
    margin-left 8px

  &__parent_link
    color: #000
    position: absolute
    top: -20px
    left: -12px
    opacity: 20%

  &__parent_icon
    width 14px
    height 14px
    margin-right 5px
    display: inline

    &_prediction
      color #944BFF

    &_annotation
      color #0099FF

  &__parent_text
    color: #000
    opacity: 40%
