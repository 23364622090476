styled-scrollbars()
  scrollbar-color: rgb(180, 180, 180) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar
    width 4px
    height 4px
    background-color #fff

  &::-webkit-scrollbar-thumb
    background rgb(180, 180, 180)
