.sort-menu
  &__option-inner
    width: 135px
    display: flex
    justify-content: space-between
  &__title
    display flex
    align-items center
  &__icon
    display flex
    align-items center
    justify-content: center;
    width: 24px
    height: 24px
    margin-right: 4px

  &__icon > *
    margin: 0 !important