.dynamic-preannotations
  top 0
  height 44px
  display flex
  z-index 100
  align-items center
  position sticky
  box-sizing border-box
  background-color #fff

  &__wrapper
    width 100%

  &__action
    padding 0
    margin 0
    margin 2px
    width 28px
    height 28px
    display flex
    align-items center
    justify-content center
    border-radius 100%
    color rgba(#000, 0.6)
    background-color rgba(#000, 0.1)

    &_type
      &_accept svg
        width 15px
        height 10px

      &_reject svg
        width 12.5px
        height 12.5px
