.image
  top 0
  position absolute
  overflow hidden

.image-progress
  padding  14px 16px
  display flex
  justify-content center
  background #fff
  border-radius 4px
  box-shadow 0 0 0 0.5px rgba(0,0,0,0.2)
  margin 16px 0.5px
  flex-direction column
  color #777
  font-weight bold

  &__bar
    width 200px
