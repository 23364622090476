.labels
  margin 1em 0
  display flex
  justify-content flex-start
  align-items center
  flex-flow wrap

  &_hidden
    display none

  &:not(&_inline)
    margin 0
    flex-direction column
    align-items flex-start

