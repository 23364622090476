.outliner
  --view-controls-height 40px

  &.ff_hide_all_regions
    --view-controls-height 32px

  &.ff_outliner_optim
    height: 100%

  &__empty
    padding 16px
    font-size 14px
    color rgba(#000, 0.6)

  .ff_outliner_optim &-tree
    height calc(100% - var(--view-controls-height))

.filters-info
  text-align center
  margin 22px 0 30px 0

  &-title
    font-weight 500
    font-size 12px
    color #1f1f1f

  &-description
    font-weight 400
    font-size 12px
    color #898098