.filter
  padding 10px

  &__empty
    margin-bottom 10px
    font-size 14px
    color #585858
    width 220px

.filter-button
  display flex
  height 24px
  padding 0 6px 0 2px
  cursor pointer
  align-items center
  border-radius 4px

  &:active
  &_active
    background rgba(65, 60, 74, 0.08)

  &__icon
    align-items center
    display flex

  &__filter-length
    font-size 11px
    font-weight 500
    text-align center
    color #030852
    width 15px
    height 20px
    line-height 21px
    border-radius 2px
    background #d6e4ff
    margin-left 3px