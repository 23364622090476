.annotations-list
  min-width 264px
  box-sizing border-box
  position relative

  &__draft
    border-bottom 1px solid rgba(0,0,0,0.1)

  &__list
    top calc(100% - 1px)
    left -1px
    right -1px
    position absolute
    background-color #fff
    box-shadow 0px 0px 1px rgba(#000, 0.1), 0px 2px 8px -2px rgba(#000, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset

  &__create
    border-bottom: 1px solid rgba(0,0,0,0.1);

  &__create,
  &__entity
    height 48px
    display flex
    padding 0 16px
    align-items center
    cursor pointer

    &:hover
      background-color #f3f9ff

  &__entity
    &_selected
      pointer-events none
      background-color #e3f1ff

  &__user
    font-size 14px
    line-height 16px
    width 136px
    display flex

  &__icons
    display flex
    justify-content flex-end
    align-items center

  &__icon-column
    display flex
    align-items center
    justify-content center
    &:nth-child(2)
      width: 32px
      height: 32px

  &__name
    flex 1
    white-space nowrap
    text-overflow ellipsis
    overflow hidden

  &__entity-id
    font-size 11px
    opacity 0.5
    flex none
    padding-left 5px

  &__review
    font-size 11px
    line-height 13px

    &_state
      &_accepted
        color #2AA000
      &_fixed
        color #FA8C16
      &_rejected
        color #dd0000

  &__created
    font-size 11px
    line-height 13px
    color rgba(0, 0, 0, 0.4)

  &__date
    color rgba(0, 0, 0, 0.4)

  &__counter
    font-size 11px
    line-height 13px
    color rgba(#000, 0.4)

  &__toggle
    width 32px
    height 32px
    position relative

    &::before
      top 50%
      left 50%
      content ''
      width 10px
      height 10px
      display block
      position absolute
      border 2px solid #000
      border-bottom none
      border-right none
      margin-top -3px
      transform translate3d(-50%, -50%, 0) rotate(225deg)

    &_opened::before
      margin-top 3px
      transform translate3d(-50%, -50%, 0) rotate(45deg)
