
$settings__title
  color #1F1F1F
  font-family Roboto
  font-size 16px
  font-weight 500
  line-height 24px
  letter-spacing 0.15px
  text-align left

.settings
  &__field + &__field
    margin-top 10px

.settings_newUI
  .settings__field
    display flex
    align-items flex-start
  .settings__field + .settings__field
    margin-top 16px
  .settings__label
    flex 1
    &__title
      @extends $settings__title
      align-items center
      display flex
      gap 8px
    &__description
      font-size 14px
      font-weight 400
      line-height 20px
      letter-spacing 0.25px
      text-align left

.keys
  &__key
    padding 0 4px
    height 16px
    display inline-flex
    align-items center
    background #FFFFFF
    border-radius 2px
    font-weight bold
    font-size 12px
    text-transform uppercase
    box-shadow 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 1px 0px rgba(0, 0, 0, 0.08)

  &__key + &__key
    margin-left 4px

  &__key-group
    margin-left -8px
    margin-right -8px
    padding-left 8px
    padding-right 8px

  &__key-group + &__key-group
    margin-top 5px
    border-top 2px solid rgba(#000, 0.05)

.settings-modal
  --modal-button-text-color-active #40A9FF
  --toggle-color #1890FF
  --modal-border-radius 8px
  --modal-padding 24px

  width 100% !important
  max-width 568px !important

  :global(.ant-tabs-tab .ant-tabs-tab-btn)
    @extends $settings__title
    text-shadow none
  :global(.ant-modal-close)
    top: 28px
    right: var(--modal-padding)
  :global(.ant-modal-close-icon)
    display none
  :global(.ant-modal-close-x)
    height 24px !important
    width 24px !important
    line-height 24px !important
    color var(--modal-button-text-color-active)
  :global(.ant-tabs-tab)
    padding calc(var(--modal-padding) / 4) var(--modal-padding) !important
  :global(.ant-tabs-tab + .ant-tabs-tab)
    margin-left 0 !important
  :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn)
    color var(--modal-button-text-color-active) !important
  :global(.ant-modal-content)
    border-radius var(--modal-border-radius) !important
  :global(.ant-modal-body)
    padding 0 var(--modal-padding) var(--modal-padding) !important
  :global(.ant-modal-header)
    border-radius: var(--modal-border-radius) var(--modal-border-radius) 0 0 !important;
    padding var(--modal-padding) !important
    border none !important
  :global(.ant-modal-title)
    color #1F1F1F
    font-family Roboto
    font-size 24px
    font-weight 400
    line-height 32px
    letter-spacing 0
    text-align left

  .settings-tag
    display inline-flex
    align-items center
    justify-content center
    padding-inline 4px
    color #030852
    background-color #D6E4FF
    border-radius 2px
    height 20px
    font-size 11px
    font-weight 500
    line-height 16px
    letter-spacing 0.5px
    text-align center
