.ground-truth
  &_size
    &_md
      &, ~/__toggle, ~/__indicator
        width: 32px
        height: 32px

  &_disabled
    pointer-events: none
  &__toggle
    padding 0

  &__indicator
    color rgba(#000, 0.4)

    &_dark
      color #000

    &_active
      color #ffbb1a

      path
        fill-opacity 1
        stroke-opacity 1
