.list-item
  --color #666
  padding 0 8px 0 0

  &__title
    display flex
    flex-flow row nowrap
    align-items center

  &__counter
    color var(--color)
    margin-left 12px

  &__visibility
    padding: 0 !important
    width: 24px
    padding: 24px