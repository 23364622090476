@keyframes pulse {
  0% {
    transform scale(1)
  }
  50% {
    transform scale(0.5)
  }
}

.comment-item
  --comment-item-padding 8px
  --comment-item-spacing 6px
  --comment-item-content-font-size 14px
  --comment-item-content-line-height 20px
  --comment-item-content-letter-spacing 0.25px
  --comment-item-avatar-size 24px
  --comment-item-name-color #1f1f1f
  --comment-item-content-color #413C4A
  --comment-item-content-color-resolved #898098
  --comment-item-icon-color-resolved #389E0D

  padding var(--comment-item-padding)
  color var(--comment-item-content-color)

  & > * + * {
    margin-top var(--comment-item-spacing);
  }

  &__userpic
    width var(--comment-item-avatar-size)
    height var(--comment-item-avatar-size)

  &__name
    color var(--comment-item-name-color)
    text-overflow ellipsis
    overflow hidden
    white-space nowrap
    flex 1

  &__name,
  &__date
    font-size var(--comment-item-content-font-size)

  &__saving
    &_hide
      display none

  &__dot
    width 10px
    height 10px
    margin-right 4px
    display inline-block
    background-color rgba(#000, 0.4)
    border-radius 100%
    will-change color, opacity
    transition color 0.1s ease, opacity 0.1s ease
    animation pulse 1s linear infinite

  &__resolved
    opacity 0
    transition opacity 0.3s ease
    will-change opacity
    height 16px

    ~/_resolved &
      opacity 1
      width 12px
      margin-right 4px
      color var(--comment-item-icon-color-resolved) !important

  &__content
    width 100%
    display flex
    gap 8px
    align-items flex-start


  &__actions
    .button_size_small
      flex-shrink 0
      padding 0 !important
      width 32px !important
      height 32px !important

      &:hover
        background: linear-gradient(0deg, rgba(0,0,0,0.02), rgba(0,0,0,0.02)), #fff !important;

    .button__icon
      padding 0 !important
      width 20px !important
      height 20px !important

    svg
      width 16px !important
      height 16px !important

  &__text
    flex 1
    white-space pre-wrap
    word-break break-word
    font-size var(--comment-item-content-font-size)
    line-height var(--comment-item-content-line-height)
    letter-spacing var(--comment-item-content-letter-spacing)

    ~/_resolved &
      color var(--comment-item-content-color-resolved)

  &__question
    padding 0
  
  &__controls
    display flex
    justify-content space-between
    padding var(--comment-item-padding)


