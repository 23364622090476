.video-canvas
  width max-content
  height max-content
  position relative

  canvas
    display block

  &__view canvas
    width 100%
    height 100%

  &__toolbar
    display flex
    justify-content space-between

  &__loading
    top 0
    left 0
    width 100%
    height 100%
    display flex
    position absolute
    align-items center
    justify-content center
    background-color rgba(#000, 0.05)

  &__buffering
    left 0
    right 0
    bottom 0
    height 4px
    font-size 12px
    position absolute
    padding 2px 7px
    text-align center
    background-color #fff
    background-image repeating-linear-gradient(
      90deg,
      #0084FF 0px,
      #80C2FF 120px,
      #0084FF 240px
    )
    animation buffering 1.2s linear infinite

.spinner
  width 40px
  height 40px
  border-radius 100%
  border 2px solid rgb(235, 131, 12)
  border-right-color transparent
  border-bottom-color transparent
  animation spin 1.2s linear infinite

@keyframes spin
  0%
    transform rotate(0deg);
  100%
    transform rotate(360deg);

@keyframes buffering
  0%
    background-position 0 0
  100%
    background-position 240px 0
