.comments-section
  border-top 1px solid rgba(0,0,0,0.1)

  &__header
    display flex
    height 46px
    justify-content space-between
    padding 12px 15px
    align-items center
    font-weight 500
    font-size 16px
    line-height 22px

  &__title
    flex 1
