.video-segmentation
  z-index 1
  position relative

  &_fullscreen
    display flex
    flex-direction column
    background-color #fff

  &__main
    ~/_fullscreen &
      flex 1

  &__timeline
    ~/_fullscreen &
      flex none

.video
  video
    max-width 100%

  &_fullscreen
    height 1vh
    flex 100 1 1vh
    display flex
    flex-direction column

  &__main
    ~/_fullscreen &
      flex 1

  &__controls
    display flex
    height: 32px
    align-items center
    background-color #fff
    justify-content flex-end

    ~/_fullscreen &
      flex none
      padding 0 16px
