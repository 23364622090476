.minimap
  width 100%
  height 100%
  display flex
  align-items stretch
  flex-direction column
  justify-content center

  &__region
    height 2px
    width 100%
    padding 1px 0
    overflow hidden
    position relative
    box-sizing content-box

  &__connection
    height 2px
    position absolute
    background var(--color)
