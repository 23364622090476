.frames-control
  height 36px
  min-width 115px
  display flex
  align-items center
  justify-content center
  background rgba(#000, 0.05)
  border-radius 4px

  font-weight 500
  font-size 16px
  line-height 19px
  color #000
  padding 0 8px

  span
    opacity 0.4
    padding-left 5px
    font-weight 400

  input
    width 100%
    height 100%
    border none
    background none
    padding 0
    margin 0
    font-size 16px
    line-height 19px
    text-align center
