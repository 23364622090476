.timeline
  user-select none
  background-color #fff
  font-family "Roboto Condensed", Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif

  &__topbar
    min-height 48px
    padding 6px
    display grid
    grid-row-gap 8px
    align-items center
    grid-auto-rows min-content
    grid-template-rows 1fr
    border-top 1px solid rgba(#000, 0.1)
    border-bottom 1px solid rgba(#000, 0.1)

.audio
  min-width 425px