.audio-control
  position relative

  &__modal
    left 0
    top 36px
    position absolute
    width 232px
    background #fff
    border-radius 4px
    padding 22px 0 0
    box-shadow  0 4px 10px 0 #0000001F
    z-index 10

  &__range
    margin 0 20px
    width calc(100% - 40px)
    padding  0

  &__mute
    margin-top 10px
    padding 2px 0
    border-top 1px solid #8980981F
    font-size 16px
    cursor pointer

  &__mute-button
    padding 8px 16px

    &:hover
      background #00000014