.time-box
  width 100px
  height 24px
  border-radius 4px 0 0 4px
  border 1px solid #89809829
  font-size 14px

  &_sidepanel
    width 100%

  &_inverted
    border-left none
    border-radius 0 4px 4px 0

  &__input-time
    border none
    padding 0
    outline none
    width 100%
    height 100%
    line-height 22px
    background none
    text-align center

  &__displayed-time
    width 100%
    height 100%
    line-height 22px
    text-align center

    span
      color #B6B7B6
